import classnames from 'classnames';
import { FC } from 'react';

export enum IconNames {
  'arrow-left',
  'back',
  'backward-15',
  'backward-30',
  'forward-15',
  'book-filled',
  'book',
  'book-off',
  'burger',
  'check',
  'chevron-left',
  'chevron-right',
  'clock',
  'close-off',
  'close',
  'discount',
  'download-filled',
  'download',
  'discover-off',
  'discover',
  'eye-password-hide',
  'eye-password-show',
  'forward-30',
  'heart-off',
  'heart',
  'home-off',
  'home',
  'instagram',
  'library-off',
  'library',
  'linkedin',
  'lock',
  'pause',
  'plus',
  'play',
  'profile-off',
  'profile',
  'read',
  'settings',
  'twitter',
  'search',
  'search-off',
  'share-android',
  'star',
  'star-off',
  'stick',
  'queue-list',
  'next-media',
  'snooze',
  'less',
  'queueList-top',
  'queueList-bottom',
  'draggable',
  'more',
  'report-issue',
  'signout',
  'question',
  'shooting-star',
  'list-plus',
  'headset',
  'sound-on',
  'sound-half',
  'sound-off',
  'apple',
  'google',
  'info',
  'bell',
  'bell-on',
  'upload',
  'pencil',
  'trash',
  'warning',
  'arrows-down-up',
  'squares-four',
  'waveform',
  'create',
}

export type IconProps = {
  name: keyof typeof IconNames | IconNames;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
};

export const Icon: FC<IconProps> = ({ name, className, size = 'md' }) => (
  <svg className={`${classnames('icon', name, className)} icon-${size}`}>
    <use xlinkHref={`/fonts/Majelan.svg#${name}`} />
  </svg>
);
