import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { usePlayerStartMedia } from './usePlayerStartMedia';
import { useCallback } from 'react';
import { useQueueList } from './useQueueList';

export const usePlayerPlayNext = () => {
  const { currentMedia, queueList } = usePlayer();
  const { trackPlayerGoNext } = useTrackPlayer();
  const { startMedia } = usePlayerStartMedia();
  const { setQueueList } = useQueueList();
 
  const handlePlayNext = useCallback(() => {
    const queueListWithoutCurrentMedia = queueList?.filter(
      (media) => currentMedia?.UUID !== media.UUID
    );

    if (!queueListWithoutCurrentMedia.length) return;

    const nextMedia = queueListWithoutCurrentMedia[0];

    setQueueList(queueListWithoutCurrentMedia);

    startMedia({
      media: nextMedia,
      lastTimeInMedia: 0,
      mediasInHistory: [],
      isFromQueueList: true,
      autoPlay: true,
    });

    trackPlayerGoNext(nextMedia);
  }, [currentMedia?.UUID, queueList, setQueueList, startMedia, trackPlayerGoNext]);

  return handlePlayNext;
};
