import {
  PlayerReducerActionPayload,
  PlayerReducerActions,
  PlayerReducerState,
} from './PlayerProvider.types';

export const initialState: PlayerReducerState = {
  mediaToPlay: null,
  playing: false,
  playbackURL: '',
  played: 0,
  loaded: 0,
  duration: 0,
  seeking: false,
  playedSeconds: 0,
  alreadyPlayed: 0,
  remainingTime: 0,
  canForward: false,
  canBackward: false,
  currentMedia: undefined,
  currentProgram: null,
  queueList: [],
  queueListDisplayed: false,
  lastTimeInMedia: 0,
  snooze: false,
  speed: 1,
  sound: 0.5,
  playContext: null,
};

export const FORWARD_INTERVAL = 15;

export const playerReducer = (state: PlayerReducerState, action: PlayerReducerActionPayload) => {
  switch (action.type) {
    case PlayerReducerActions.HANDLE_PLAYING:
      return {
        ...state,
        playing: !state.playing,
        canBackward: state.playedSeconds > FORWARD_INTERVAL,
        canForward: state.playedSeconds < state.duration - FORWARD_INTERVAL,
      };

    case PlayerReducerActions.HANDLE_PROGRESS: {
      const { played, playedSeconds } = action.payload.progress;

      return {
        ...state,
        played,
        playedSeconds,
        alreadyPlayed: played * state.duration,
        remainingTime: state.duration * (1 - played),
        canBackward: playedSeconds > FORWARD_INTERVAL,
        canForward: playedSeconds < state.duration - FORWARD_INTERVAL,
      };
    }

    case PlayerReducerActions.HANDLE_BUFFERING: {
      return {
        ...state,
        loaded: action.payload.progress.loaded,
      };
    }

    case PlayerReducerActions.HANDLE_DURATION: {
      return {
        ...state,
        duration: action.payload.duration,
        alreadyPlayed: state.played * action.payload.duration,
        remainingTime: action.payload.duration * (1 - state.played),
        canBackward: state.playedSeconds > FORWARD_INTERVAL,
        canForward: state.playedSeconds < action.payload.duration - FORWARD_INTERVAL,
      };
    }

    case PlayerReducerActions.HANDLE_SNOOZE: {
      return {
        ...state,
        snooze: action.payload.snooze,
      };
    }

    case PlayerReducerActions.HANDLE_SPEED: {
      return {
        ...state,
        speed: action.payload.speed,
      };
    }

    case PlayerReducerActions.HANDLE_DISPLAY_QUEUELIST: {
      return {
        ...state,
        queueListDisplayed: action.payload.queueListDisplayed,
      };
    }

    case PlayerReducerActions.HANDLE_SEEK_MOUSE_DOWN: {
      return {
        ...state,
        seeking: true,
      };
    }

    case PlayerReducerActions.HANDLE_SEEK_CHANGE: {
      return {
        ...state,
        played: action.payload.played,
      };
    }

    case PlayerReducerActions.HANDLE_SEEK_MOUSE_UP: {
      return {
        ...state,
        seeking: false,
      };
    }

    case PlayerReducerActions.HANDLE_START: {
      return {
        ...state,
        playing: true,
      };
    }

    case PlayerReducerActions.HANDLE_STOP: {
      return {
        ...state,
        playing: false,
      };
    }

    case PlayerReducerActions.HANDLE_FORWARD: {
      return {
        ...state,
        playedSeconds: action.payload.playedSeconds,
      };
    }

    case PlayerReducerActions.HANDLE_BACKWARD: {
      return {
        ...state,
        playedSeconds: action.payload.playedSeconds,
      };
    }

    case PlayerReducerActions.HANDLE_SOUND: {
      return {
        ...state,
        sound: action.payload.sound,
      };
    }

    case PlayerReducerActions.SET_PROGRAM: {
      return {
        ...state,
        currentProgram: action.payload.currentProgram,
      };
    }

    case PlayerReducerActions.SET_MEDIA_TO_PLAY: {
      return {
        ...state,
        mediaToPlay: action.payload.mediaToPlay,
        lastTimeInMedia: action.payload.mediaToPlay.lastTimeInMedia,
        mediasInHistory: action.payload.mediaToPlay.mediasInHistory,
        isFromQueueList: action.payload.mediaToPlay.isFromQueueList,
      };
    }

    case PlayerReducerActions.SET_MEDIA: {
      return {
        ...state,
        currentMedia: action.payload.currentMedia,
        lastTimeInMedia: action.payload.lastTimeInMedia,
      };
    }

    case PlayerReducerActions.SET_PLAYBACK_URL: {
      return {
        ...state,
        playbackURL: action.payload.playbackURL,
      };
    }

    case PlayerReducerActions.SET_QUEUE_LIST: {
      return {
        ...state,
        queueList: action.payload.queueList,
      };
    }

    case PlayerReducerActions.SET_PLAY_CONTEXT: {
      return {
        ...state,
        playContext: action.payload.playContext,
      };
    }

    default:
      return state;
  }
};
