import { useQuery } from '@tanstack/react-query';
import { ReactNode, createRef, useContext, useEffect, useReducer } from 'react';
import ReactPlayer from 'react-player';

import { getMedia, getPlaybackUrl } from '@/modules/medias/domain/resources';
import { getQueueList } from '@/modules/player/domain/resources';
import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { getProgram } from '@/modules/programs/domain/resources';

import { PlayerDispatchContext } from './PlayerDispatchContext';
import { PlayerContext } from './PlayerProvider.context';
import { initialState, playerReducer } from './PlayerProvider.reducer';

interface PlayerProviderProps {
  children?: ReactNode;
}

export const PlayerProvider = ({ children }: PlayerProviderProps) => {
  const [state, dispatch] = useReducer(playerReducer, initialState);

  const shouldRefetchMedia = !!(
    state?.mediaToPlay?.media?.mediaKeyId &&
    state?.mediaToPlay?.media?.UUID !== state?.currentMedia?.UUID
  );

  const shouldRefetchProgram = !!(
    state?.mediaToPlay?.media?.programMediaKeyId &&
    state?.mediaToPlay?.media?.programUUID !== state?.currentMedia?.programUUID
  );

  const shouldRefetchPlaybackURL = !!(
    state?.mediaToPlay?.media?.mediaKeyId &&
    state?.mediaToPlay?.media?.UUID !== state?.currentMedia?.UUID
  );

  const shouldRefetchQueueList = !!(
    state?.mediaToPlay?.media?.programMediaKeyId &&
    state?.mediaToPlay?.media?.episode &&
    state?.mediaToPlay?.media?.UUID !== state?.currentMedia?.UUID
  );

  const { data: media } = useQuery({
    queryKey: ['media', state?.mediaToPlay?.media?.mediaKeyId],
    queryFn: () => getMedia(state?.mediaToPlay?.media?.mediaKeyId),
    enabled: shouldRefetchMedia,
  });

  const { data: program } = useQuery({
    queryKey: ['program', state?.mediaToPlay?.media?.programMediaKeyId],
    queryFn: () => getProgram(state?.mediaToPlay?.media?.programMediaKeyId),
    enabled: shouldRefetchProgram,
  });

  const { data: playbackURL } = useQuery({
    queryKey: ['media-playback-url', state?.mediaToPlay?.media?.mediaKeyId],
    queryFn: () => getPlaybackUrl(state?.mediaToPlay?.media?.mediaKeyId),
    enabled: shouldRefetchPlaybackURL,
  });

  const { data: queueList } = useQuery({
    queryKey: [
      'queue-list',
      state?.mediaToPlay?.media?.programMediaKeyId,
      state?.mediaToPlay?.media?.episode,
    ],
    queryFn: () =>
      getQueueList(
        state?.mediaToPlay?.media?.programMediaKeyId,
        state?.mediaToPlay?.media?.episode
      ),
    enabled: shouldRefetchQueueList,
  });

  useEffect(() => {
    if (!media) return;

    dispatch({
      type: PlayerReducerActions.SET_MEDIA,
      payload: { currentMedia: media, lastTimeInMedia: state?.lastTimeInMedia },
    });
  }, [media, state?.lastTimeInMedia]);

  useEffect(() => {
    if (!program) return;

    dispatch({
      type: PlayerReducerActions.SET_PROGRAM,
      payload: { currentProgram: program },
    });
  }, [program]);

  useEffect(() => {
    if (!playbackURL) return;

    dispatch({
      type: PlayerReducerActions.SET_PLAYBACK_URL,
      payload: { playbackURL },
    });
  }, [playbackURL]);

  return (
    <PlayerContext.Provider value={state}>
      <PlayerDispatchContext.Provider value={dispatch}>{children}</PlayerDispatchContext.Provider>
    </PlayerContext.Provider>
  );
};

export const playerRef = createRef<ReactPlayer>();

export function usePlayer() {
  return useContext(PlayerContext);
}

export function usePlayerDispatch() {
  return useContext(PlayerDispatchContext);
}
