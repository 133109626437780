import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerStop } from './usePlayerStop';
import { usePlayerStartMedia } from './usePlayerStartMedia';
import { useMediasInHistoryByProgram } from '@/hooks/useMediasInHistoryByProgram';
import { useCallback } from 'react';
import { useQueueList } from './useQueueList';

export const usePlayerEnded = () => {
  const { currentMedia, queueList } = usePlayer();
  const handleStop = usePlayerStop();
  const { startMedia } = usePlayerStartMedia();
  const { mediasInHistory } = useMediasInHistoryByProgram(currentMedia?.programUUID);
  const { setQueueList } = useQueueList();

  const handleEnded = useCallback(async () => {
    const queueListWithoutEndedMedia = queueList?.filter(
      (media) => currentMedia?.UUID !== media.UUID
    );
    if (!queueListWithoutEndedMedia.length) {
      handleStop();

      return;
    }

    const nextMedia = queueListWithoutEndedMedia?.[0];

    setQueueList(queueListWithoutEndedMedia);

    const history = mediasInHistory.find((history) => history.UUID === nextMedia?.UUID);

    startMedia({
      media: nextMedia,
      lastTimeInMedia: history?.lastTimeInMedia || 0,
      mediasInHistory: mediasInHistory || [],
      isFromQueueList: true,
      autoPlay: true,
    });
  }, [currentMedia?.UUID, handleStop, mediasInHistory, queueList, setQueueList, startMedia]);

  return handleEnded;
};
