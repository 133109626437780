import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { Media } from '@/types';
import { useTrackQueueList } from '@/modules/analytic/hooks/useTrackQueueList';
import { useCallback } from 'react';
import { useQueueList } from './useQueueList';

export const usePlayerRemoveFromQueueList = () => {
  const { queueList } = usePlayer();
  const { trackRemoveFromQueue } = useTrackQueueList();
  const { setQueueList } = useQueueList();

  const removeFromQueueList = useCallback(
    async (media: Media) => {
      const oldQueueList = queueList ? queueList : [];

      const newQueueList = oldQueueList.filter(
        (mediaFromQueue) => mediaFromQueue.UUID !== media.UUID
      );

      setQueueList(newQueueList);

      trackRemoveFromQueue({
        inQueue: queueList,
        removed: [media],
      });
    },
    [queueList, setQueueList, trackRemoveFromQueue]
  );

  return removeFromQueueList;
};
