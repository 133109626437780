import { mapMedia } from "@/services/mapMedia";
import { requestQuery } from "@/services/request";
import { HOUR, Media } from "@/types";
import { Flow } from "@/types/flow";
import { useQuery } from "@tanstack/react-query"

export const useGetFlow = () => {
  return useQuery({
    queryKey: ['flow'],
    cacheTime: 3 * HOUR,
    queryFn: async (): Promise<Array<Media>> => {
      const response = await requestQuery<Flow>({
        url: 'flow'
      });

      const medias = response.items.map((flowItem) => mapMedia(flowItem.item));

      return medias;
    }
  })
}