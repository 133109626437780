import { SquaresFour, UserCircle, Waveform } from '@phosphor-icons/react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import { NavigationLink } from '@/types';

import { Icon } from './Icon';

export const TabMenu = () => {
  const { formatMessage } = useIntl();
  const menuItems: NavigationLink[] = [
    {
      id: 'flow',
      href: '/flow',
      label: 'layout.nav.flow',
      testId: 'flow',
      phosphorIcon: <Waveform size={28} />,
    },
    {
      id: 'home',
      href: '/hub',
      label: 'layout.nav.home',
      testId: 'home',
      phosphorIcon: <SquaresFour size={28} />,
    },
    {
      id: 'profile',
      href: '/library/history',
      label: 'layout.nav.profile',
      testId: 'profile',
      phosphorIcon: <UserCircle size={28} />,
    },
  ];

  return (
    <div className="flex">
      <div className="w-full h-[71px] pt-3 bg-[#12111C]">
        <ul className="flex flex-row justify-around h-full">
          {menuItems.map((item, id) => (
            <li key={id}>
              <Link href={item.href} className="flex flex-col items-center">
                {item.phosphorIcon}
                <span className="font-bold text-xs text-white leading-4">
                  {formatMessage({ id: item.label })}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
