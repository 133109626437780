import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerPlayNext } from '@/modules/player/providers/dispatch/usePlayerPlayNext';

export const Next = () => {
  const { formatMessage } = useIntl();

  const { queueList } = usePlayer();
  const handlePlayNext = usePlayerPlayNext();

  const isDisabled = useMemo(() => (queueList || []).length <= 0, [queueList]);

  return (
    <button
      className="next-button"
      onClick={handlePlayNext}
      aria-label={formatMessage({ id: 'player.accessibility.next' })}
      disabled={isDisabled}
    >
      <Icon name="next-media" className="text-xl mb-[2px]" />
    </button>
  );
};
