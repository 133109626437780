import React, { memo } from 'react';

import { BackForward } from '@/modules/player/presentation/atoms/BackForward';
import { Next } from '@/modules/player/presentation/atoms/Next';
import { QueueListButton } from '@/modules/player/presentation/atoms/QueueListButton';
import { SimplePlayPause } from '@/modules/player/presentation/atoms/SimplePlayPause';
import { Snooze } from '@/modules/player/presentation/atoms/Snooze';
import { Sound } from '@/modules/player/presentation/atoms/Sound';
import { Speed } from '@/modules/player/presentation/atoms/Speed';
import { MediaInfo } from '@/modules/player/presentation/molecules/MediaInfo';
import { ProgressBar } from '@/modules/player/presentation/molecules/ProgressBar';
import { QueueList } from '@/modules/player/presentation/molecules/QueueList';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';

const PlayerMobileWrapper = () => {
  const { queueListDisplayed } = usePlayer();

  return (
    <div className="player-mobile-wrapper">
      {!queueListDisplayed && <MediaInfo isPlayerMobile={true} />}

      <ProgressBar />

      <div className="primary-actions">
        <Speed />
        <BackForward direction="backward" />
        <SimplePlayPause />
        <BackForward direction="forward" />
        <Next />
      </div>

      <div className="secondary-actions">
        <QueueListButton />
        <Snooze />
        <Sound />
      </div>

      {queueListDisplayed && <QueueList />}
    </div>
  );
};

export const PlayerMobile = memo(PlayerMobileWrapper);
