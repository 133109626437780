import { CorporateMedia, DomainTypes, Media, MediaInitializer } from "@/types"
import { mediaRoute, proMediaRoute, shareMediaRoute, shareProMediaRoute } from "@/utils/routes"

export const mapMedia = (media: MediaInitializer | CorporateMedia): Media => { 
  const mappedMedia: Media = 'UUID' in media ? {
    ...media,
    domain: 'majelan',
    audioUUID: media.audioUUID,
    mediaKeyId: '',
    programMediaKeyId: '',
    isAvailable: true,
    isCorporate: false,
    isNew: false,
    isComingSoon: false,
    route: mediaRoute(media.UUID, media.programUUID),
    shareRoute: shareMediaRoute(media.UUID, media.programUUID),
    cover: media.verticalPictureURL,
    program: {
      uuid: media.program.uuid,
      title: media.program.title,
      mediasCount: media.program.mediasCount,
    }
  } : {
      source: DomainTypes.Corporate,
      type: 'media',
      domain: media.domain, 
      UUID: media.uuid,
      audioUUID: '',
      description: media.description,
      duration: media.duration,
      enabled: true,
      endPublicationDate: null,
      episode: media.order,
      lang: 'fr',
      programUUID: media.program.uuid,
      playbackURL: media.uri ?? null,
      publicationDate: null,
      status: 'live',
      summary: media.description,
      syllabus: [],
      title: media.name,
      uploadURL: media.uri,
      verticalPictureURL: media.program.coverPortrait,
      creationDate: 0,
      modificationDate: 0,
      deleted: false,
      mediaKeyId: '',
      programMediaKeyId: '',
      isAvailable: true,
      isCorporate: true,
      isNew: false,
      isComingSoon: false,
      route: proMediaRoute(media.uuid, media.program.uuid, media.domain),
      shareRoute: shareProMediaRoute(media.uuid, media.program.uuid, media.domain),
      cover: media.program.coverPortrait ?? media.program.coverLandscape,
      program: {
        uuid: media.program.uuid,
        title: media.program.name,
        mediasCount: media.program.mediasCount,
      }
    }
  
  mappedMedia.mediaKeyId = `A::${mappedMedia.source}::${mappedMedia.domain}::${mappedMedia.programUUID}::${mappedMedia.UUID}`
  mappedMedia.programMediaKeyId = `A::${mappedMedia.source}::${mappedMedia.domain}::${mappedMedia.programUUID}`

  return mappedMedia
}