import { Gear } from '@phosphor-icons/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useClickOutside } from '@/hooks/useClickOutside';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { useLogout } from '@/modules/auth/hooks/useLogout';
import { NavigationLink } from '@/types';
import { config } from '@/utils';
import {
  emailingRoute,
  personalizationRoute,
  profileRoute,
  suggestionFormRoute,
  websiteHelpRoute,
} from '@/utils/routes';

import { Icon } from './Icon';

interface AccountNavigationProps {
  handleOpenCouponModal: () => void;
}

export const AccountNavigation = ({ handleOpenCouponModal }: AccountNavigationProps) => {
  const { formatMessage } = useIntl();
  const { route } = useRouter();
  const { user } = useAuth();
  const { logout } = useLogout();
  const [navOpen, setNavOpen] = useState(false);
  const { screenWidth } = useWindowSize();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closeNavigation = useCallback(() => setNavOpen(false), [setNavOpen]);
  const navigationRef = useClickOutside(closeNavigation, [buttonRef]);

  const menuItems: NavigationLink[] = [
    {
      id: 'account',
      href: profileRoute,
      label: 'layout.nav.settings.account',
      external: false,
      icon: 'settings',
    },
    {
      id: 'personalization',
      href: personalizationRoute,
      label: 'layout.nav.settings.personalization',
      external: false,
      icon: 'stick',
    },
    {
      id: 'promoCode',
      onClick: handleOpenCouponModal,
      label: 'layout.nav.settings.promoCode',
      external: false,
      icon: 'discount',
      hidden: user?.isCorporate || user?.premium,
    },
    {
      id: 'contact',
      href: emailingRoute,
      label: 'layout.nav.settings.contact',
      external: false,
      icon: 'headset',
    },
    {
      id: 'faq',
      href: websiteHelpRoute,
      label: 'layout.nav.settings.faq',
      external: true,
      icon: 'question',
    },
    {
      id: 'content',
      href: suggestionFormRoute,
      label: 'layout.nav.settings.suggest.content',
      external: true,
      icon: 'list-plus',
    },
    {
      id: 'improvement',
      href: emailingRoute,
      label: 'layout.nav.settings.suggest.improvement',
      external: false,
      icon: 'shooting-star',
    },
  ];

  useEffect(() => {
    closeNavigation();
  }, [route, screenWidth, closeNavigation]);

  const toggleNavigation = useCallback(() => {
    setNavOpen(!navOpen);
  }, [navOpen, setNavOpen]);

  const emailData = formatMessage(
    { id: 'contact.email.content' },
    { appVersion: config.version, userId: user?.UUID }
  );

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <>
      <ul className="mt-3">
        <li>
          <button
            ref={buttonRef}
            onClick={toggleNavigation}
            aria-label={formatMessage({ id: 'layout.nav.settings.account.toggle' })}
            className={classNames({ active: navOpen }, 'gap-4')}
          >
            <Gear size={28} />
            <span>{formatMessage({ id: 'layout.nav.settings.settings' })}</span>
          </button>
        </li>
      </ul>
      <aside
        role="menu"
        ref={navigationRef}
        className={classNames('account-navigation', { open: navOpen })}
        aria-label={formatMessage({ id: 'navigation.accessibility.web' })}
      >
        <nav role="navigation">
          <ul>
            {menuItems.map(
              ({ id, href, onClick, label, external, icon, hidden }) =>
                !hidden && (
                  <li key={id}>
                    {onClick ? (
                      <button onClick={onClick}>
                        {icon && <Icon name={icon} />}
                        <span>{formatMessage({ id: label })}</span>
                      </button>
                    ) : (
                      <Link
                        /* @TODO: EMAILING is not a route */
                        href={href === 'EMAILING' ? emailData : href}
                        target={external ? '_blank' : ''}
                        role="link"
                        aria-label={formatMessage(
                          { id: 'navigation.accessibility.navLink' },
                          { navLink: `${formatMessage({ id: label })}` }
                        )}
                      >
                        {icon && <Icon name={icon} />}
                        <span>{formatMessage({ id: label })}</span>
                      </Link>
                    )}
                  </li>
                )
            )}
            <li>
              <button
                onClick={handleLogout}
                aria-label={formatMessage({ id: 'navigation.accessibility.logout' })}
              >
                <Icon name="signout" />
                <span>{formatMessage({ id: 'layout.nav.logout' })}</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
};
