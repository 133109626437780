import { mapMedia } from '@/services/mapMedia';
import { requestQuery } from '@/services/request';
import { Media } from '@/types';

export const getMedias = async (UUIDs: string[]) => {
  const mediaUUIDs = UUIDs.filter(Boolean);
  try {
    const response = await requestQuery<Media[]>({
      method: 'POST',
      url: 'medias/get',
      data: { UUIDs: mediaUUIDs },
    });

    return (response || []).map((d) => mapMedia(d));
  } catch (e) {
    return [];
  }
};
