import { IconNames } from '@/components/Icon';

export type PopoverAction = {
  id: string;
  accessibility: string;
  icon: keyof typeof IconNames | null;
  action: () => void;
  available?: boolean;
};

export enum PagesWithTabs {
  'account',
  'library',
}

export enum Breakpoints {
  ExtraExtraSmall = 546,
  ExtraSmall = 640,
  Small = 768,
  Medium = 1024,
  Large = 1280,
  ExtraLarge = 1536,
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;