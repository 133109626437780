import { config } from './config';

export const websiteOffersRoute = `${config.majelanWebsite}/offers`;
export const websiteActivateRoute = `${config.majelanWebsite}/activate`;
export const websitePrivacyPolicyRoute = `${config.majelanWebsite}/privacy-policy`;
export const websiteTermsRoute = `${config.majelanWebsite}/terms-service`;

export const websiteGoIosDownload = `${config.majelanWebsiteGo}/website-ios-download`;
export const websiteGoAndroidDownload = `${config.majelanWebsiteGo}/website-android-download`;

export const websiteHelpRoute = config.majelanWebsiteHelp || 'https://aide.majelan.com/';

export const suggestionFormRoute =
  'https://docs.google.com/forms/d/e/1FAIpQLSfKOnkUletA5IVY_XmznTR6vXQDGO4IlQKaqOLHozZDeG7lNg/viewform';

export const mailtoRoute = 'mailto:hello@majelan.com';

export const callbackRoute = `/auth/callback`;
export const linkRoute = '/auth/link';
export const activationRoute = '/auth/activation';
export const passwordResetRoute = '/auth/reset';
export const passwordForgottenRoute = '/password-forgotten';
export const magicLinkRoute = '/magiclink';
export const loginLink = `login`;
export const signupLink = `signup`;
export const loginRoute = `/${loginLink}`;
export const signupRoute = `/${signupLink}`;

export const profileRoute = '/account/profile';
export const personalizationRoute = '/personalization';
export const subscriptionRoute = '/account/subscription';
export const followingRoute = `/library/following`;
export const resumeRoute = `/library/resume`;
export const historyRoute = `/library/history`;

export const programRoute = (programUUID: string) => `/programs/${programUUID}`;
export const proProgramRoute = (programUUID: string, domainId: string) =>
  `/${domainId}/programs/${programUUID}`;

export const mediaRoute = (mediaUUID: string, programUUID: string) =>
  `/programs/${programUUID}/${mediaUUID}`;
export const proMediaRoute = (mediaUUID: string, programUUID: string, domainId: string) =>
  `/${domainId}/programs/${programUUID}/${mediaUUID}`;

export const shareMediaRoute = (mediaUUID: string, programUUID: string) =>
  `/player/${programUUID}/${mediaUUID}`;
export const shareProMediaRoute = (mediaUUID: string, programUUID: string, domainId: string) =>
  `/${domainId}/player/${programUUID}/${mediaUUID}`;

export const audiosRoute = `/library/audio`;
export const audioPath = `/library/audio/[UUID]`;
export const audioRoute = (audioUUID: string) => `/library/audio/${audioUUID}`;

/* @TODO: EMAILING is not a route */
export const emailingRoute = 'EMAILING';
export const absoluteSigninRoute = `${config.majelanWebapp}/login`;
export const authEmailRoute = (email: string) => `/auth/magic?email=${encodeURIComponent(email)}`;

export const isOnAuth = (pathname: string) =>
  [
    loginRoute,
    signupRoute,
    passwordForgottenRoute,
    magicLinkRoute,
    callbackRoute,
    linkRoute,
    activationRoute,
    passwordResetRoute,
  ].includes(pathname);
  
export const proRoutes = [audiosRoute, audioPath];
