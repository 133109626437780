import { PlusSquare, SquaresFour, Waveform } from '@phosphor-icons/react';
import { UserCircle } from '@phosphor-icons/react/dist/ssr';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { AudioModal } from '@/modules/audio/presentation/modals/AudioModal';
import { AudioOnboardingModal } from '@/modules/audio/presentation/modals/AudioOnboardingModal';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { CouponModal } from '@/modules/subscriptions/presentation/modals/CouponModal';
import { NavigationLink } from '@/types';
import {
  retrieveFeaturesSession,
  retrieveOnboardingSession,
  setOnboardingSession,
} from '@/utils/browserStorage';

import { AccountNavigation } from './AccountNavigation';
import { Button } from './Button';
import { Icon } from './Icon';

export const SideBar = () => {
  const { formatMessage } = useIntl();
  const { route } = useRouter();
  const { user } = useAuth();
  const [isOpenAudioModal, setIsOpenAudioModal] = useState(false);
  const [isOpenAudioOnboardingModal, setIsOpenAudioOnboardingModal] = useState(false);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);

  const menuItems: NavigationLink[] = [
    {
      id: 'flow',
      href: '/flow',
      label: 'layout.nav.flow',
      testId: 'flow',
      phosphorIcon: <Waveform size={28} />,
    },
    {
      id: 'home',
      href: '/hub',
      label: 'layout.nav.home',
      testId: 'home',
      phosphorIcon: <SquaresFour size={28} />,
    },
    {
      id: 'profile',
      href: '/library/history',
      label: 'layout.nav.profile',
      testId: 'profile',
      phosphorIcon: <UserCircle size={28} />,
    },
  ];

  const isFileToAudioAllowed = retrieveFeaturesSession()?.fileToAudio;

  const handleAddAudio = () => {
    const hasSeenOnboarding = retrieveOnboardingSession()?.audio;

    if (!hasSeenOnboarding) {
      setIsOpenAudioOnboardingModal(true);
      setOnboardingSession({ audio: true });
      return;
    }
    setIsOpenAudioModal(true);
  };

  const handleCloseAudioModal = useCallback(() => {
    setIsOpenAudioModal(false);
  }, []);

  const handleCloseAudioOnboardingModal = useCallback(() => {
    setIsOpenAudioOnboardingModal(false);
    setIsOpenAudioModal(true);
  }, []);

  const handleOpenCouponModal = useCallback(() => {
    setIsOpenCouponModal(true);
  }, []);

  const handleCloseCouponModal = useCallback(() => {
    setIsOpenCouponModal(false);
  }, []);

  return (
    <>
      <nav
        className="fixed"
        role="navigation"
        aria-label={formatMessage({ id: 'navigation.accessibility.web' })}
      >
        <Link
          href={'/flow'}
          aria-label={formatMessage({ id: 'navigation.accessibility.home' })}
          className="logo"
        >
          {user?.isCorporate ? (
            <Image src="/logo-majelan-pro.png" alt="Majelan Pro logo" width={160} height={30} />
          ) : (
            <Image src="/logo-majelan.png" alt="Majelan logo" width={150} height={30} />
          )}
        </Link>

        <ul className="main-navigation">
          {menuItems.map((item, id) => {
            const isCurrentNav = route.split('/')[1] === item.href?.split('/')?.[1];

            return (
              <li key={id}>
                <Link
                  href={item.href || ''}
                  role="link"
                  aria-label={formatMessage(
                    { id: 'navigation.accessibility.navLink' },
                    { navLink: `${formatMessage({ id: item.label })}` }
                  )}
                  className={classNames({ active: isCurrentNav }, 'gap-4')}
                >
                  {item.phosphorIcon}
                  <span>{formatMessage({ id: item.label })}</span>
                </Link>
              </li>
            );
          })}
          {isFileToAudioAllowed && (
            <li>
              <Button style="primary" onClick={handleAddAudio}>
                <PlusSquare size={28} />
                {formatMessage({ id: 'layout.nav.addAudio' })}
              </Button>
            </li>
          )}
        </ul>

        {!user?.isCorporate && !user?.premium && (
          <div className="promoCodeElement">
            <Icon name="discount" />

            <span className="promoCodeElementTitle">
              {formatMessage({ id: 'layout.nav.promoCode.title' })}
            </span>

            <span className="promoCodeElementDescription">
              {formatMessage({ id: 'layout.nav.promoCode.description' })}
            </span>

            <Button
              onClick={handleOpenCouponModal}
              aria-label={formatMessage({ id: 'navigation.accessibility.promoCode' })}
              className="contents font-bold"
            >
              {formatMessage({ id: 'layout.nav.promoCode.button' })}
            </Button>
          </div>
        )}

        <AccountNavigation handleOpenCouponModal={handleOpenCouponModal} />
      </nav>
      {isOpenCouponModal && <CouponModal onClose={handleCloseCouponModal} />}
      {isOpenAudioModal && <AudioModal onClose={handleCloseAudioModal} />}
      {isOpenAudioOnboardingModal && (
        <AudioOnboardingModal onClose={handleCloseAudioOnboardingModal} />
      )}
    </>
  );
};
