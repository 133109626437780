import { CorporateMedia } from '@/types';
import { getData } from '@/utils/corpo/request/getData';
import { mapMedia } from '@/services/mapMedia';

export const getMedias = async () => {
  try {
    const response = await getData<{ data: CorporateMedia[] }>('medias');

    return (response?.data || []).map((d) => mapMedia(d));
  } catch (e) {
    return [];
  }
};
