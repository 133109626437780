import { useCallback, useContext } from "react";
import { PlayerDispatchContext } from "@/modules/player/providers/PlayerDispatchContext";
import { Media } from "@/types";
import { PlayerReducerActions } from "@/modules/player/providers/PlayerProvider.types";

/**
 * Manipulates the queue list of the app. Allows to set or empty the queue list.
 * @returns 
 */
export const useQueueList = () => {
  const dispatch = useContext(PlayerDispatchContext);

  const setQueueList = useCallback(
    (medias: Array<Media>) => {
      dispatch({
        type: PlayerReducerActions.SET_QUEUE_LIST,
        payload: { queueList: medias },
      });
    }, [dispatch]
  )

  const emptyQueueList = useCallback(
    () => {
      dispatch({
        type: PlayerReducerActions.SET_QUEUE_LIST,
        payload: { queueList: [] }
      })
    }, [dispatch]
  )

  return { setQueueList, emptyQueueList };
}