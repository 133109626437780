import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerPlaying } from '@/modules/player/providers/dispatch/usePlayerPlaying';

export interface SimplePlayPauseProps {
  className?: string;
}

/**
 * The SimplePlayPause button is only used in the big player. It does not switch the play context
 * and is only available when the big player is loaded with content to play.
 * @returns T
 */
export const SimplePlayPause = ({ className = '' }: SimplePlayPauseProps) => {
  const { formatMessage } = useIntl();
  const { handlePlayPause } = usePlayerPlaying();
  const { playing, currentMedia } = usePlayer();

  return (
    <button
      className={`play-button ${className}`}
      onClick={handlePlayPause}
      type="button"
      aria-label={formatMessage({
        id: `player.accessibility.${playing ? 'pause' : 'play'}`,
      })}
    >
      <Icon
        name={playing ? 'pause' : 'play'}
        className={classNames({
          'text-brand-neutral-300': !currentMedia,
        })}
      />
    </button>
  );
};
