import { ReactNode, memo } from 'react';

import { SideBar } from '@/components/SideBar';
import { TabMenu } from '@/components/TabMenu';
import { Notifications } from '@/modules/notifications/presentation/Notifications';
import { Player } from '@/modules/player/presentation/Player';

interface LayoutProps {
  withAuth?: boolean;
  hasHistoryBack?: boolean;
  withPlayer?: boolean;
  fullScreen?: boolean;
  children: ReactNode;
  onClickBack?: () => void;
}

const AuthenticatedLayout = ({ withAuth = false, children }: LayoutProps) => {
  return (
    <div id="layout">
      <Notifications />
      <div className="flex w-full h-full overflow-auto flex-row">
        <div className="navigation">{!withAuth && <SideBar />}</div>
        {children}
      </div>
      <div>
        <Player />
      </div>
      <div className="w-full md:hidden">
        <TabMenu />
      </div>
    </div>
  );
};

export default memo(AuthenticatedLayout);
